import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
// Material-UI
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";

import {
  isAdmin,
  //SERVICE_ADMIN_ROLE,
} from "../auth/roles";

// Development time
import PropTypes from "prop-types";

// Styling
import "./containers.css";
// Own components
import {
  fetchAnalyticsForAccount,
  fetchAggregatedAnalytics,
} from "../actions/adminActions";
import CostPane from "../components/CostPane";
import ElementButton from "../components/inputs/ElementButton";

const analyticsImg = process.env.PUBLIC_URL + "/assets/icons/analytics.png";
const useStyles = makeStyles({
  root: {
    flexGrow: 1,
    background: "white",
    paddingTop: 30,
    paddingBottom: 60,
    paddingLeft: 62,
    paddingRight: 0,
  },
});

const section = {
  height: "100%",
  paddingTop: 30,
  //backgroundColor: "#fff",
};

/*
const Thousand = 1000;
const TenThousand = Thousand * 10;
const HundredThousand = Thousand * 100;
const Million = Thousand * 1000;
const TenMillion = Million * 10;
const HundredMillion = Million * 100;
*/

const displayValue = (value) => {
  return value.toLocaleString("en-US");
  /*
  if (value < Thousand) return value;
  if (value < TenThousand) return (value / Thousand).toFixed(2) + "K";
  if (value < HundredThousand) return (value / Thousand).toFixed(1) + "K";
  if (value < Million) return (value / Thousand).toFixed(0) + "K";
  if (value < TenMillion) return (value / Million).toFixed(2) + "M";
  if (value < HundredMillion) return (value / Million).toFixed(1) + "M";
  return (value / Million).toFixed(0) + "M";
  */
};

function getFormattedDate() {
  const now = new Date();
  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  const formattedMonth = monthNames[now.getMonth()];
  const formattedYear = now.getFullYear().toString().slice(-2);
  return `[${formattedMonth}, '${formattedYear}]`;
}

const Card = ({ title, data, image, bold }) => {
  return (
    <div className="card">
      <div className="content">
        <h2 className="title">
          <span style={{ fontWeight: "normal" }}>{title}</span>
        </h2>
        <span style={{ fontWeight: bold ? "bold" : "normal" }}>
          <p className="data">{data}</p>
        </span>
      </div>
      <div className="image-container">
        <img src={image} alt="Card" height={64} />
      </div>
    </div>
  );
};

Card.propTypes = {
  image: PropTypes.string.isRequired,
  data: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  bold: PropTypes.bool.isRequired,
};

const DataPane = ({ title, data, image }) => {
  const [bold, setBold] = useState(false);

  useEffect(() => {
    setBold(true);
    const timeoutId = setTimeout(() => {
      setBold(false);
    }, 500);
    return () => clearTimeout(timeoutId);
  }, [data]);

  const path = (name) => {
    const fullP = process.env.PUBLIC_URL + `/assets/icons/${name}`;
    return fullP;
  };

  return (
    <Card
      title={title}
      data={displayValue(data)}
      image={path(image)}
      bold={bold}
    />
  );
};

DataPane.propTypes = {
  image: PropTypes.string.isRequired,
  data: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
};

const baseFee = 1000;

const calculateCost = (account, prize, analytics, index) => {
  const { isFreeAccount } = account;
  const { cost, max, min } = prize;
  const activePlayers = analytics ? analytics.activePlayers : 0;
  if (isFreeAccount && max <= 1000) return "0.00 €";
  if (isFreeAccount) return null;
  if (activePlayers >= min && activePlayers <= max) {
    if (index === 1) return `${baseFee} €`;
    return `${(baseFee + cost * activePlayers).toFixed(2)} €`;
  }
  return null;
};

const prizingModel = [
  // First value: for free account
  {
    cost: 0.0,
    min: 0,
    max: 999,
  },
  // 2nd, fixed price
  {
    cost: 0.0,
    min: 0,
    max: 24999,
  },
  {
    cost: 0.02,
    min: 25000,
    max: 499999,
  },
  {
    cost: 0.01,
    min: 500000,
    max: 1000000000,
  },
];

const Analytics = (props) => {
  const classes = useStyles();

  useEffect(() => {
    const { account } = props;
    const gAnalytics = async () => {
      if (account.account !== "") {
        props.getAnalyticsForAccount(account.account);
      } else if (isAdmin()) {
        props.getAggregatedAnalytics();
      }
    };
    gAnalytics();

    const intervalId = setInterval(gAnalytics, 5000);

    return () => clearInterval(intervalId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.account]);

  const data = props.analytics;

  const dateStr = getFormattedDate();
  const { isFreeAccount, isDevAccount, hasCustomPrizing } = props.account;

  return (
    <div className={classes.root}>
      <Grid
        container
        spacing={2}
        alignItems="center"
        justifyContent="center"
        style={{ minWidth: 1490 }}
      >
        <Grid container row alignItems="center">
          <Grid item xs={10}>
            <img src={analyticsImg} height={64} alt="" />
            <div>
              <div className="tabletitle">Analytics</div>
              <div className="tablesubtitle">Keep track of what’s going on</div>
            </div>
          </Grid>
          <Grid item xs={2}>
            <div>
              <div style={{ paddingRight: 20 }}>
                <div style={{ paddingBottom: 10 }}>
                  <ElementButton
                    id="id-btn-support"
                    variant="contained"
                    size="small"
                    color="blue"
                    type="submit"
                    onClick={() => {
                      window.open(
                        "mailto:support@gamelayer.co?subject=Support%20Request%20for%20GameLayer",
                        "_blank"
                      );
                    }}
                  >
                    Support
                  </ElementButton>
                </div>
                {isFreeAccount && (
                  <div>
                    <ElementButton
                      id="id-btn-upgrade"
                      variant="contained"
                      size="small"
                      color="blue"
                      type="submit"
                      onClick={() => {
                        window.open(
                          "https://pay.gocardless.com/BRT00037R5GASCS",
                          "_blank"
                        );
                      }}
                    >
                      Upgrade
                    </ElementButton>
                  </div>
                )}
              </div>
            </div>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <div style={section}></div>
        </Grid>
        <Grid item xs={6}>
          {" "}
          <DataPane
            data={
              data && data.activePlayers
                ? `${displayValue(data.activePlayers)} - ${dateStr}`
                : `0 - ${dateStr}`
            }
            title="Monthly Active Players:"
            image="active_user.png"
          />
        </Grid>
        <Grid item xs={6}>
          {!hasCustomPrizing && prizingModel != null && !isDevAccount && (
            <div className="card">
              {prizingModel.map((prize, index) => (
                <div key={prize.max} style={{ paddingRight: 10 }}>
                  <CostPane
                    key={prize.max}
                    players={
                      index === prizingModel.length - 1
                        ? prize.min
                        : prize.max + 1
                    }
                    totalCost={calculateCost(props.account, prize, data, index)}
                    pricePerPlayer={prize.cost}
                    comparator={index === prizingModel.length - 1 ? ">" : "<"}
                  />
                </div>
              ))}
            </div>
          )}
          {hasCustomPrizing && (
            <div className="cardtext">Custom Pricing Model</div>
          )}
          {isDevAccount && (
            <div className="cardtext">Development Testing Account</div>
          )}
        </Grid>
        <Grid item xs={6}>
          {" "}
          <DataPane
            data={data && data.players ? data.players : 0}
            title="Players Registered:"
            image="players.png"
          />
        </Grid>
        <Grid item xs={6}>
          {" "}
          <DataPane
            data={data && data.apiCalls ? data.apiCalls : 0}
            title="API Calls Processed:"
            image="api.png"
          />
        </Grid>
        <Grid item xs={6}>
          {" "}
          <DataPane
            data={data && data.startedMissions ? data.startedMissions : 0}
            title="Missions Started:"
            image="missions.png"
          />
        </Grid>
        <Grid item xs={6}>
          {" "}
          <DataPane
            data={data && data.missions ? data.missions : 0}
            title="Missions Completed:"
            image="missions.png"
          />
        </Grid>
        <Grid item xs={6}>
          {" "}
          <DataPane
            data={
              data && data.unlockedAchievements ? data.unlockedAchievements : 0
            }
            title="Achievements Unlocked:"
            image="achievements.png"
          />
        </Grid>
        <Grid item xs={6}>
          {" "}
          <DataPane
            data={data && data.achievements ? data.achievements : 0}
            title="Achievements Granted:"
            image="achievements.png"
          />
        </Grid>
        <Grid item xs={6}>
          {" "}
          <DataPane
            data={data && data.points ? data.points : 0}
            title="Points Earned:"
            image="points.png"
          />
        </Grid>
        <Grid item xs={6}>
          {" "}
          <DataPane
            data={data && data.credits ? data.credits : 0}
            title="Credits Earned:"
            image="credits.png"
          />
        </Grid>
        <Grid item xs={6}>
          {" "}
          <DataPane
            data={data && data.levelUps ? data.levelUps : 0}
            title="Level-Ups:"
            image="levels.png"
          />
        </Grid>
        <Grid item xs={6}>
          {" "}
          <DataPane
            data={data && data.prizes ? data.prizes : 0}
            title="Prizes Redeemed:"
            image="prizes.png"
          />
        </Grid>
        <Grid item xs={6}>
          {" "}
          <DataPane
            data={data && data.prizeDraws ? data.prizeDraws : 0}
            title="Raffles Entered:"
            image="raffles.png"
          />
        </Grid>
        <Grid item xs={6}>
          {" "}
          <DataPane
            data={data && data.mysteryBoxes ? data.mysteryBoxes : 0}
            title="Mystery Boxes Opened:"
            image="mysteryboxes.png"
          />
        </Grid>
        <Grid item xs={6}>
          {" "}
          <DataPane
            data={data && data.quizzes ? data.quizzes : 0}
            title="Quizzes Completed:"
            image="quizzes.png"
          />
        </Grid>
        <Grid item xs={6}>
          {" "}
          <DataPane
            data={data && data.surveys ? data.surveys : 0}
            title="Surveys Completed:"
            image="surveys.png"
          />
        </Grid>
        <Grid item xs={6}>
          {" "}
          <DataPane
            data={data && data.streaksStarted ? data.streaksStarted : 0}
            title="Active Streaks:"
            image="streaks.png"
          />
        </Grid>
        <Grid item xs={6}>
          {" "}
          <DataPane
            data={data && data.streaksCompleted ? data.streaksCompleted : 0}
            title="Streaks Completed:"
            image="streaks.png"
          />
        </Grid>
      </Grid>
    </div>
  );
};

Analytics.propTypes = {
  analytics: PropTypes.object.isRequired,
  account: PropTypes.object.isRequired,
  getAnalyticsForAccount: PropTypes.func,
  getAggregatedAnalytics: PropTypes.func,
};

const mapStateToProps = (state) => ({
  analytics: state.input.analytics,
  account: state.input.account,
});
const mapDispatchToProps = (dispatch) => ({
  getAnalyticsForAccount: (account) =>
    dispatch(fetchAnalyticsForAccount(account)),
  getAggregatedAnalytics: () => dispatch(fetchAggregatedAnalytics()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Analytics);
